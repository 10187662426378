
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { Stock } from '@/entities/sales'
  import { Deal, Lead } from '@/entities/crm'
  import dayjs from 'dayjs'
  import { utcToLocal } from '@/utils/general'

interface ICriteria {
  title: string
  filter: string
  useValue?: boolean
}

@Component({
  components: {
    Row: () => import('../simple.vue'),
  },
})
  export default class StockLeads extends Vue {
  @Prop({ type: Object, default: () => ({}) }) value!: Stock
  @Prop({ type: String, default: undefined }) categoryName?: string
  @Prop({ type: String, default: undefined }) categoryDescription?: string
  @Prop({ default: () => [] }) criterias?: ICriteria[]
  categories: Record<string, any>[] = []

  mounted () {
    const { deals, leads, categoryName, noCategory, criterias } = this

    if (criterias?.length) {
      this.categories = criterias.map(criteria => {
        return {
          title: criteria.title,
          count: criteria.useValue ? this[criteria.filter]?.(deals) : leads?.filter(this[criteria.filter])?.length,
        }
      })
    } else {
      const categories = []

      leads?.forEach(lead => {
        const leadCategory = lead[categoryName] || noCategory
        if (!categories.map(category => category.id).includes(leadCategory.id)) {
          categories.push(leadCategory)
        }
      })

      this.categories = categories.map(category => {
        return {
          title: category.description,
          count: leads?.filter(lead => (lead[categoryName] || noCategory).id === category.id)?.length,
        }
      })
    }
  }

  total (deals: Deal[]) {
    return deals?.length || 0
  }

  active (deals: Deal[]) {
    return deals.filter(deal => deal.isDealOpen)?.length || 0
  }

  lastWeek (deals: Deal[]) {
    return deals?.filter(deal => dayjs().diff(deal.createdAt, 'day') <= 7)?.length || 0
  }

  noLeadDays (deals: Deal[]) {
    const lastLead = deals
      ?.filter(deal => deal.createdAt)
      ?.sort((prev, next) => next.createdAt.diff(prev.createdAt))?.[0]
    if (!lastLead) return 0

    return dayjs().diff(utcToLocal(lastLead?.createdAt || this.value.createdAt), 'day')
  }

  get noCategory () {
    const { categoryDescription, categoryName } = this
    return {
      id: 999999,
      name: `no_${categoryName}`,
      description: `Sin ${categoryDescription}`,
    }
  }

  get key () {
    return JSON.stringify(this.categories)
  }

  get deals (): Deal[] {
    const { value } = this

    return value?.deals
  }

  get leads (): Lead[] {
    const { value } = this

    return value?.deals?.map(deal => deal.lead)
  }
  }
